<template>
  <div class="exercise-subpage">
    <div class="d-flex flex-column justify-content-center">
      <Entry title="Diamond agility"
             img_name="diamond-agility.png"
             yt_video_id="82nEmepJ1gc"
             description="Count down 12 points as quickly as you can, by going through the lighted up cones. After each point scored, you need to go back to the middle, and then move onto the next target."/>

      <Entry title="Off agility"
             img_name="off-agility.png"
             yt_video_id="RExeBtbSGj4"
             description="Position the gates, 120 degrees relative to the starting point in the middle, 6 meters away. Turn on 1s delay. Sprint towards the lighted up gate, and collect 9 points as quickly as possible."/>

      <Entry title="Inwards agility"
             img_name="inwards-agility.png"
             yt_video_id="odwi1E95Wis"
             description="Start off next to the lighted up cone and collect 9 points, arriving into the lighted up gate facing inwards."/>

      <Entry title="Outwards agility"
             img_name="outwards-agility.png"
             yt_video_id="1Ni2NHXazpI"
             description="Start off by going through the lighted up cone, counting down 9 points, facing outwards while arriving into each lighted up gate."/>
    </div>
  </div>
</template>

<script>
import Entry from "@/components/Entry";

export default {
  name: "AgilityPage",
  components: {
    Entry
  }
}
</script>
